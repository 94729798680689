import { useEffect, useState } from 'react';

export const usePreferReducedMotion = () => {
  const [reduced, setReduced] = useState(false);

  useEffect(() => {
    if (window && window.matchMedia('(prefers-reduced-motion)').matches) {
      setReduced(true);
    }
  }, []);

  return reduced;
};
