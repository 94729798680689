import { Toast } from '@/molecules';

import { useToast } from '@/lib/hooks';

import type { FunctionComponent } from 'react';
import type { TypeToastCenterProps } from './types';

/**
 * ToastCenter
 */
export const ToastCenter: FunctionComponent<TypeToastCenterProps> = ({
  className,
  style = {
    position: 'absolute',
    zIndex: -10,
    width: '100%',
    inset: 'auto',
  },
  children,
}: TypeToastCenterProps) => {
  const toasts = useToast();

  return (
    <div {...(className && { className })} style={style}>
      {toasts.map((toast) => (
        <div
          key={toast.id}
          style={{
            display: 'flex',
            position: 'absolute',
            transition: 'all 230ms cubic-bezier(0.21, 1.02, 0.73, 1) 0s',
            zIndex: toast.visible ? 10 : -10,
            width: '100%',
            justifyContent: 'center',
          }}
        >
          {children ? (
            children(toast)
          ) : (
            <Toast
              {...toast}
              className={`${
                toast.visible ? 'animate-toast-out' : 'animate-toast-in'
              }`}
            />
          )}
        </div>
      ))}
    </div>
  );
};
